<template>
  <div>
    <div v-if="currentUserRole === Role.systemAdmin">
      <div
        class="candidate-dashboard pb-4 container-md col-lg-10 col-md-10 mb-4"
      >
        <h4 class="mb-3 text-center text-primary">System Dashboard</h4>

        <div id="parent_child_container">
          <h3>
            <span>{{ customers.length }}</span
            >Customers
          </h3>
          <div>
            <CRow>
              <CCol
                lg="3"
                col="6"
                md="4"
                class="p-1"
                v-for="(data, index) in customers"
                :key="index"
              >
                <div class="card" @click="isShowChild(data)">
                  <a href="#">
                    <h6 v-if="data.child_length > 0" class="link">
                      <span>{{ data.child_length }}</span
                      >&nbsp;Child
                    </h6>
                    <img :src="logo(data.logo_url)" @error="imageLoadOnError" />
                    <div class="ca-content mt-2">
                      <h6 class="ca-main">{{ data.name }}</h6>
                      <label class="ca-sub">{{ data.customer_type }}</label
                      ><br />
                      <label class="plan">{{ data.variant }}</label>
                    </div>
                  </a>
                </div>
              </CCol>
            </CRow>
          </div>
        </div>

        <!-- End -->
        <!--
        <CRow class="row tile pt-4">
          <CCol
            sm="12"
            lg="4"
            v-for="({ id, label, value }, index) in getCandidateData"
            :key="index"
          >
            <div class="card">
              <div class="card-body d-flex align-items-center p-3">
                <div
                  class="mr-3 p-3 icon-wrapper"
                  :style="getTheme(id).text_color"
                >
                  <i :class="getTheme(id).src"></i>
                </div>
                <div>
                  <div class="text-value" :style="getTheme(id).text_color">
                    {{ value }}
                  </div>
                  <div class="text-muted font-weight-bold small">{{ label }}</div>
                </div>
              </div>
            </div>
          </CCol>
        </CRow>
        

        <CRow class="justify-content-center mt-3 pb-4 mb-2">
          <CButton color="primary" class="px-4 mr-3" variant="outline"
            >Clear</CButton
          >
          <CButton color="primary" class="px-4 mr-3" @click="routeToCandidateList"
            >Close</CButton
          >
        </CRow>
        -->
      </div>
      <div>
        <Slider
          v-if="showChild && organisationList.length"
          :child="organisationList"
          @sliderModelCallBack="sliderModelCallBack"
        />
      </div>
    </div>
    <CRow v-else class="py-2 px-4">
      <CCol
        sm="4"
        lg="2"
        v-for="({ id, label, value }, index) in getCandidateData"
        :key="index"
      >
        <div class="stats text-primary">
          <i class="stats-icon" :class="getTheme(id).src"></i>
          <h2 class="count">{{ value }}</h2>
          <p class="text-center">{{ label }}</p>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import Slider from "@/containers/Dashboard/ChildSideBar";
import { Role, CANDIDATE_URL } from "@/helpers/helper";

export default {
  name: "Dashboard",
  components: {
    Select,
    Slider,
  },
  data() {
    return {
      Role,
      CANDIDATE_URL,
      small: true,
      striped: false,
      bordered: true,
      fields: [
        { key: "geography", label: "Geography" },
        { key: "agency", label: "Agency" },
        { key: "physician", label: "Physician" },
        { key: "allied_health", label: "Allied Health" },
        {
          key: "allied_health_professional",
          label: "Pharmacist - Allied Health Professional",
        },
        { key: "dentist", label: "Dentist" },
        { key: "nurse", label: "Nurse" },
      ],
      showChild: false,
      child: [],
    };
  },
  computed: {
    ...mapGetters([
      "getCandidateByType",
      "getRoleScopes",
      "customerList",
      "organisationList",
    ]),
    currentUserRole() {
      return this.getRoleScopes.length ? this.getRoleScopes[0] : "";
    },
    getCandidateData() {
      let getCandidateData =
        this.getCandidateByType &&
        this.getCandidateByType.candidate_stats &&
        this.getCandidateByType.candidate_stats.summary &&
        this.getCandidateByType.candidate_stats.summary.map((data) => {
          const getData = {};
          getData.id = data.candidate_type_id;
          getData.label = data.candidate_type;
          getData.value = data.num_candidates;
          return getData;
        });
      //let agency = { id: 0, label: "Agencyf", value: "--" };
      //getCandidateData ? getCandidateData.push(agency) : [];
      return getCandidateData;
    },
    getItems() {
      let getItems = {};
      this.getCandidateData
        ? this.getCandidateData.map((data) => {
            data.id == 1 ? (getItems["physician"] = data.value) : null;
            data.id == 2 ? (getItems["nurse"] = data.value) : null;
            data.id == 3 ? (getItems["allied_health"] = data.value) : null;
            data.id == 4 ? (getItems["dentist"] = data.value) : null;
            data.id == 5
              ? (getItems["allied_health_professional"] = data.value)
              : null;
            data.id == 6 ? (getItems["agency"] = data.value) : null;
            getItems["geography"] = "--";
          })
        : null;
      return [getItems];
    },
    customers() {
      let customers =
        (this.customerList &&
          this.customerList.length &&
          this.customerList.map((val) => ({
            main_contact_name: `${val.main_user?.first_name || ""} ${val
              .main_user?.surname || ""}`,
            email: val.main_user?.email || "--",
            institution_name: val.name || "--",
            customer_type: val.customer_type?.comments || "--",
            main_contact_id: val.main_contact_id,
            main_user_id: val.main_user_id,
            customer_uid: val?.customer_uid,
            parent_org_id: val?.main_organisation_id,
            parent: val?.main_organisation?.is_parent ? "Yes" : "No",
            is_parent: val?.main_organisation?.is_parent,
            city: val?.customer_organisation
              ? val?.customer_organisation
                  .map((e) =>
                    e?.customer_organisation_location.map(
                      (loc) => loc?.location?.location
                    )
                  )
                  .join(", ")
              : "--",
            customer_type_id: val?.customer_type_id,
            path_acronym: val?.path_acronym || "--",
            organisation_id: val?.main_organisation_id,
            theme_style: val?.main_organisation?.theme_style,
            logo_url: val?.main_organisation.logo_url,
            name: val?.name,
            child_length: val?.customer_organisation?.length - 1 || 0,
            varient: val?.varient?.[0].value,
          }))) ||
        [];
      return _.sortBy(customers, [
        function(o) {
          return o.name;
        },
      ]);
    },
  },
  mounted() {
    this.fetchCustomerList();
    this.FetchCandidateByType();
  },
  methods: {
    ...mapActions([
      "FetchCandidateByType",
      "fetchCustomerList",
      "fetchOrganisationListForCustomer",
      "fetchContactType",
    ]),
    getTheme(id) {
      let src = "";
      let color = "";
      switch (id) {
        case 0:
          src = "fas fa-clinic-medical";
          color = "#35c792;";
          break;
        case 1:
          src = "fas fa-user-md";
          color = "#8a08a2;";
          break;
        case 2:
          src = "fas fa-user-nurse";
          color = "#1973e8";
          break;
        case 3:
          src = "fas fa-notes-medical";
          color = "#61892F;";
          break;
        case 4:
          src = "fas fa-tooth";
          color = "#e01f66;";
          break;
        case 5:
          src = "fas fa-prescription-bottle-alt";
          color = "#B23850;";
          break;
        case 6:
          src = "fas fa-hospital";
          color = "#895061;";
          break;
        default:
          src = "fas fa-user-shield";
          color = "#A64AC9;";
      }
      return {
        src: src,
        bg_color: "background-color: " + color,
        color: "color: " + color,
        text_color: "color: " + color,
      };
    },
    handleChangeSelect() {},
    routeToCandidateList() {
      this.$router.push(this.CANDIDATE_URL);
    },
    logo(data) {
      return data ? data : "/img/hospital.png";
    },
    imageLoadOnError(e) {
        e.target.src = "/img/hospital.png"
    },
    isShowChild(data) {
      this.showChild = true;
      let payload = {
        customer_uid: data.customer_uid,
        parent_org_id: data.parent_org_id,
      };
      this.fetchOrganisationListForCustomer(payload);
    },
    sliderModelCallBack() {
      this.showChild = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-label {
  white-space: nowrap;
}
.text-size {
  font-size: 14px;
}
.card-body:hover {
  box-shadow: -1px 2px 7px 8px #e8e8e885;
}
@media only screen and (max-width: 968px) {
  .search-label {
    margin-top: 0px;
  }
}
@media only screen and (min-width: 768px) {
  .search-label {
    margin-top: 3px;
    width: 80%;
    text-align: left;
  }
}
@media only screen and (max-width: 480px) {
  .filter-col {
    flex: 0 0 98.33333%;
    max-width: 98.33333%;
  }
  .filter-label {
    margin: 10px;
  }
}

//Kesan - Work start

@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

#parent_child_container {
  h3 {
    span {
      background: #eee;
      border-radius: 3px;
      color: $red-soft;
      padding: 0px 10px;
      margin-right: 3px;
    }
  }
  .card:hover {
    opacity: 1;
    -webkit-animation: flash 1.5s;
    animation: flash 1.5s;
    box-shadow: -1px 2px 4px 0px #888888;
  }

  .card {
    border-radius: 0px;
    border: 5px solid #fff;
    background: #e2f0ff;
    transition: all 300ms linear; 
    text-align: center;
    margin: 0px;
    padding: 5px;
    cursor: pointer;
    height: 100%;
    img {
      height: auto;
      width: 40%;
      border-radius: 50%;
      align-self: center;
    }
    .plan {
      background: #ee7174;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 3px;
      color: #eee;
      font-size: 0.7em;
    }

    .link {
      position: absolute;
      right: 11px;
      color: $red-soft;
    }
    a {
      text-decoration: none; 
      text-align: center;
    }
    .ca-content {
      .ca-main {
          color: #3a699b;
          margin-bottom: 0px;
      }
      .ca-sub { 
        color: #7a7676;
        font-size: 0.8em;
        margin-bottom: 0px;
      }
      h6 {
        padding-left: 2px;
        padding-right: 2px;
      }
    }
  }
}
</style>
