<template>
<div>
  <div class="modal-backdrop" @click="modelCallBack">
    </div>
    <div class="zap-slideout">
      <div class="zap-slideout-opener" @click="modelCallBack">
        <i style="color:#EEE" class="fas fa-times"></i>
      </div>
      <div id="child-company">
        <CRow class="row tile">
          <CCol
            col="12"
            class="text-holder"
            v-for="(data, index) in organisations"
            :key="index"
          >
            <CRow>
              <!--<CCol col="4">
                <img src="/img/hospital.png" />
              </CCol>-->
              <CCol col="12">
                <h6>{{ data.organisation_name }}</h6>
                <label>{{ data.organisation_type }}</label>
                <label class="plan">{{ data.variant }}</label>
              </CCol>
              <CCol class="grid">
                <CCol
                  col="12"
                  class="pl-0"
                  v-for="(data, index) in data.contact_user"
                  :key="index"
                >
                  <li v-if="data.value.length">
                    <span>{{ data.value.length }}</span
                    >{{ data.label }}
                  </li>
                </CCol>
              </CCol>
            </CRow>
          </CCol>
        </CRow>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChildSideBar",
  components: {},
  props: {
    child: {
      type: Array,
      default: [],
    },
  },
  data: () => ({
    openerText: "Close",
    isOpen: false,
    showModelBackdrop: true,
    menu: ["Home", "Work", "Contact"],
    smallMenu: ["Tips", "Resources", "Shenanigans"],
  }),
  computed: {
    organisations() {
      return (
        (this.child?.length &&
          this.child
            .filter((val) => {
              return this.is_recruiter_agency && this.show_associated_orgs
                ? val.is_associated && val.organisation_id != this.parent_org_id
                : !val.is_associated &&
                    val.organisation_id != this.parent_org_id;
            })
            .map(({ organisation, contact_user, variant, ...rest }) => ({
              organisation_name: organisation?.name,
              organisation_type:
                organisation?.organisation_type?.organisation_type,
              main_contact_name: organisation?.main_contact?.name,
              email: organisation?.main_contact?.email,
              city: rest?.customer_organisation_location
                ? rest?.customer_organisation_location
                    .map((val) => val?.location?.location)
                    .join(", ")
                : "--",
              path_acronym: rest?.path_acronym,
              customer_uid: rest?.customer_uid,
              organisation_id: rest.organisation_id,
              theme_style: organisation?.theme_style,
              contact_user: this.filterRole(contact_user),
              variant: variant[0].value,
            }))) ||
        []
      );
    },
  },
  methods: {
    modelCallBack() {
      this.$emit("sliderModelCallBack");
    },
    filterRole(data) {
    let roles = [
      { code: 1, label: "CMO", value: [] },
      { code: 2, label: "COO", value: [] },
      { code: 3, label: "CEO", value: [] },
      { code: 4, label: "Deputy CMO", value: [] },
      { code: 13, label: "Agency", value: [] },
      { code: 5, label: "Recruitment ", value: [] },
      { code: 6, label: "HR", value: [] },
      { code: 7, label: "Admin", value: [] },
      { code: 8, label: "Director", value: [] },
      { code: 9, label: "Senior Management", value: [] },
      { code: 10, label: "Management", value: [] },
      { code: 11, label: "Secretary", value: [] },
      { code: 12, label: "Account Management", value: [] },
    ]
      for (let j = 0; j < data.length; j++) {
        for (let i = 0; i < roles.length; i++) {
          if (data[j].contact_type_id == roles[i].code) {
            roles[i].value.push(data[j]);
          }
        }
      }
      return roles;
    },
  },
};
</script>

<style scoped lang="scss">
.zap-slideout {
  color: red;
  position: absolute;
  overflow-y: auto;
  z-index: 99999999;
  top: 62px;
  right: 0px;
  width: 289px;
  height: 94vh;
  padding: 30px;
  background-color: #4f4f4f;
  transform: translateX(0%);
  transition: transform 0.9s ease(out-cubic);
  @media (min-width: 960px) {
    width: 400px;
  }
}

.isOpen {
  transform: translateX(100%);
}

.zap-slideout-opener {
  position: absolute;
  top: 2px;
  right: 2px;
  margin-left: 20px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: #000;
  cursor: pointer;
}

.zap-slideout-menu {
  font-weight: 600;
  color: #fff;
}

.zap-slideout-menu-item--small {
  font-size: 18px;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  color: #ecf0f1;
}
#child-company {
  height: 100%;
  vertical-align: top;

  label {
    color: $red-soft;
    text-decoration: underline;
    font-size: 10px;
  }
  h5 {
    font-size: 13px;
    text-align: left;
    color: #eeeeee;
  }
  img {
    border-radius: 50%;
    border: none;
    height: auto;
    width: 100%;
  }

  .plan {
    background: #ee7174;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 3px;
    color: #eee;
    font-size: 0.7em;
    text-decoration: none;
    margin-left: 5px;
  }

  .ca-sub {
    color: #7a7676;
  }
  .text-holder {
    margin-bottom: 10px;
    padding: 12px;
    border-bottom: solid 1px #6c6a6a;
    h6 {
      color: #eee;
    }
    label {
      color: #eee;
      margin-bottom: none;
    }
  }
  .grid {
    display: flex;
    text-align: center;
    flex-direction: row;
    flex-wrap: wrap;
    li {
      list-style: none;
      width: 100%;
      padding: 2px 0px;
      text-align: left;
      color: #4bc7e3;
      font-size: 11px;
      span {
        background: #4bc7e3;
        padding-left: 5px;
        color: #000;
        padding-right: 5px;
        margin-right: 2px;
        border-radius: 3px;
      }
    }
  }
}
ul {
  margin: 0;
  padding: 0;
}
.modal-backdrop {
  background-color: #38383869;
}
</style>
